import React, { Component } from 'react'

export default class OpeningHours extends Component {
    render() {
        return (
            <div className="container opening-hours">
                <h2>Opening Hours</h2>
                <div className="description"><p>We always want to help, give us a call on <strong>022 309 7494</strong> and we can make plans to get your air conditioning working better than ever.</p></div>
                <div class="weekdays"><div class="weekday"><div class="title">Monday</div><div class="hours"><div class="hour">7:00 AM - 5:00 PM</div></div></div><div class="weekday"><div class="title">Tuesday</div><div class="hours"><div class="hour">7:00 AM - 5:00 PM</div></div></div><div class="weekday"><div class="title">Wednesday</div><div class="hours"><div class="hour">7:00 AM - 5:00 PM</div></div></div><div class="weekday"><div class="title">Thursday</div><div class="hours"><div class="hour">7:00 AM - 5:00 PM</div></div></div><div class="weekday"><div class="title">Friday</div><div class="hours"><div class="hour">7:00 AM - 5:00 PM</div></div></div><div class="weekday"><div class="title">Saturday</div><div class="hours"><div class="hour">7:00 AM - 1:00 PM</div></div></div><div class="weekday"><div class="title">Sunday</div><div class="hours"><div class="hour">CLOSED</div></div></div></div>
            </div>
        )
    }
}
