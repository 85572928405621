import React, { Fragment, Component } from 'react'
import { Helmet } from "react-helmet"

// COMPONENTS
import Header from '../components/header'
import Footer from '../components/footer'

// STYLESHEETS
import "./../scss/build.scss";

class Layout extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Mobile Automotive Air Conditioning Services - Tauranga, Mount Maunganui, Waihi, Katikati, Te Puke, Papamoa and loads more!</title>

                    <meta name="keywords" content="car air conditioning, Tauranga, Mount Maunganui, vehicle air conditioning, Alpine Air, auto air conditioning repairs, auto air conditioning service, air conditioning servicing, air conditioning, air conditioner, cooling, automotive air conditioning, auto air conditioning, mobile business, Hamilton, Huntly, Matamata, Morrinsville, Cambridge, Te Awamutu, Te Kauwhata, Ngaruawahia, re-gassing, repairs, preventative maintenance, servicing, odour elimination, cabin filters, pollen filters, auto parts, automotive replacements, automotive" />
                    <meta name="description" content="For mobile automotive air conditioning servicing and repairs in Tauranga, Alpine Air Tauranga has been the trusted name since 2000. Call the team on 022 309 7494."></meta>

                    <meta property="og:title" content="Mobile Automotive Air Conditioning Services - Waikato and South Canterbury" />
                    <meta property="og:description" content="For mobile automotive air conditioning servicing and repairs in Tauranga, Alpine Air Tauranga has been the trusted name since 2000. Call the team on 022 309 7494." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://alpineairtauranga.co.nz/" />
                    <meta property="og:site_name" content="alpineairtauranga.co.nz" />
                    <link rel="canonical" href="https://alpineairtauranga.co.nz/"></link>
                </Helmet>

                <div className="full-container">
                    <Header />
                    {this.props.children}
                    <Footer />
                </div>
            </Fragment>
        )
    }
}

export default Layout