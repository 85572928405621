import React, { Fragment, Component } from 'react'
import Logo from './../images/logo.png'

export default class Header extends Component {
    pageScroll = (sectionName, element) => {
        const section = document.querySelector(element);
        if(document.body.contains(section)) {
            window.scrollTo(0, section.offsetTop);
        }
    }

    render() {
        return (
            <Fragment>
                <header>
                    <img src={Logo} alt="Business Logo" />
                </header>
                <nav>
                    <div className="pages">
                        <div className="page hide-mobile" onClick={() => this.pageScroll('Home','body')}>Home</div>
                        <div className="page" onClick={() => this.pageScroll('Contact Us','.container.pricing')}>Contact Us</div>
                        <div className="page" onClick={() => this.pageScroll('Opening Hours','.container.opening-hours')}>Service Hours</div>
                        <div className="page" onClick={() => this.pageScroll('Service Areas','.container.directions')}>Service Areas</div>
                    </div>
                </nav>
            </Fragment>
        )
    }
}
