import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="details">
                    <div className="detail"><strong>Phone:</strong> 022 309 7494</div>
                    <div className="detail"><strong>Text:</strong> 022 309 7494</div>
                    <div className="detail"><strong>Email:</strong> alpineairtauranga@gmail.com</div>
                </div>
            </footer>
        )
    }
}
