import React, { Fragment, Component } from 'react'

export default class Directions extends Component {
  render() {
    return (
      <Fragment>
        <div className="container directions">
          <h2>Service Areas</h2>
          <div className="description">
            <p>
              We service everybody in the wider <strong><a href="/">Tauranga</a></strong> region
              and beyond including <strong><a href="/waihi">Waihi</a>, <a href="/tepuke">Te Puke</a>, <a href="/katikati">Katikati</a></strong> and
              a lot more.
            </p>
            <p>
              Give us a call on <strong>022 309 7494</strong> or an email at{' '}
              <strong>alpineairtauranga@gmail.com</strong> and we can arrange a
              time to carry out an inspection of your air conditioning unit and
              provide you with an appraisal
            </p>
          </div>
        </div>
        <iframe
          title="Alpine Air servicing area"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d404159.36670985096!2d176.2542874!3d-37.6845712!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xce8687c2d87282a6!2sAlpine+Air+Tauranga+Ltd!5e0!3m2!1sen!2snz!4v1508788498604"
          width="100%"
          height="350"
          frameBorder="0"
        ></iframe>
      </Fragment>
    )
  }
}
